<template>
<div>
    <div class="row">
        <div class="col-xs-12 col-sm-12 col-md-12 col-xl-6 border-right border-bottom pb-3 pt-3">
          <h5 class="card-title">Study Stats</h5>
          <apexchart type="bar" :options="baroptions" :series="barseries"></apexchart>
          <div class="row">
            <div class="col-xs-12 col-sm-12 col-lg-12 col-xl-12 pt-3 d-none d-lg-block studyCenter">
        <div class="d-flex">
          <!-- <div class="mb-2 mr-2 btn-transition btn btn-outline-info">14 FEB 2021</div> -->
          <div class="ml-auto p-2 mb-2  btn-transition btn btn-outline-info pointer-courser" @click="showStudyCenter('study-center')">Study center</div>
        </div>
      </div>
          </div>
        </div>
        <div class="col-xs-12 col-sm-12 col-md-12 col-xl-6 border-bottom pb-3 pt-3">
          <h5 class="card-title">Monthly Statistics</h5>
          <div class="row colorChange">
            <div class="col-md-7 m-auto">
              <apexchart type="radialBar" :options="options" :series="series"></apexchart>
            </div>
            <div class="col-md-5">
              <div class="card mb-3 widget-content pointer-courser" >
                  <div class="widget-content-outer">
                    <div class="widget-content-wrapper">
                        <div class="widget-content-left">
                          <div class="widget-heading">New</div>
                          </div>
                          <div class="widget-content-right">
                              <div class="widget-numbers text-redSuccess">{{predictExam.New}}</div>
                          </div>
                      </div>
                  </div>
              </div>
              <div class="card mb-3 widget-content pointer-courser" @click="questionStatusView('predict')">
                  <div class="widget-content-outer">
                    <div class="widget-content-wrapper">
                        <div class="widget-content-left">
                          <div class="widget-heading">Predict</div>
                          </div>
                          <div class="widget-content-right">
                              <div class="widget-numbers text-redSuccess">{{predictExam.predict}}</div>
                          </div>
                      </div>
                  </div>
              </div>
              <div class="card mb-3 widget-content pointer-courser" @click="questionStatusView('exam')">
                  <div class="widget-content-outer">
                    <div class="widget-content-wrapper">
                        <div class="widget-content-left">
                          <div class="widget-heading">Exam</div>
                          </div>
                          <div class="widget-content-right">
                              <div class="widget-numbers text-redSuccess">{{predictExam.exam}}</div>
                          </div>
                      </div>
                  </div>
              </div>
            </div>
          </div>
          <div class="row">
      
      <div class="col-xs-12 col-sm-12 col-lg-12 col-xl-12 pt-3 d-none d-lg-block viewAll">
        <div class="d-flex">
          <!-- <div class="mb-2 mr-2 btn-transition btn btn-outline-success">14 FEB 2021</div> -->
          <div class="ml-auto p-2 mr-2 mb-2 btn-transition btn btn-outline-success  pointer-courser" @click="questionStatusView('all')">View all</div>
        </div>
      </div>
    </div>
        </div>
    </div>
    
  </div>
</template>
<script>
import apexchart from 'vue-apexcharts'
import { mapActions,mapGetters } from 'vuex'
export default {
  components: {
    apexchart
  },
  data () {
    return {
      user_type:'',
      predictExam:{
        predict:0,
        Exam:0,
        New:1895
      },
      baroptions: {
        chart: {
          id: 'vuechart-example'
        },
        colors: ['#001848','#194db5','#5979b7'],
        plotOptions: {
          bar: {
            distributed: true
          }
        },
        xaxis: {
          categories: ['Today Prac', 'Total Prac', 'Prac. Days']
        }
      },
      barseries: [{
        name: 'Practice Data',
        data: [0, 0, 0]
      }],
      options: {
        chart: {
          height: 280,
          type: 'radialBar'
        },
        series: [67],
        colors: ['#001848'],
        plotOptions: {
          radialBar: {
            hollow: {
              margin: 0,
              size: '60%',
              background: '#293450'
            },
            track: {
              dropShadow: {
                enabled: true,
                top: 2,
                left: 0,
                blur: 4,
                opacity: 0.15
              }
            },
            dataLabels: {
              name: {
                offsetY: -10,
                color: '#fff',
                fontSize: '13px'
              },
              value: {
                color: '#fff',
                fontSize: '30px',
                show: true
              }
            }
          }
        },
        fill: {
          type: 'gradient',
          gradient: {
            shade: 'dark',
            type: 'vertical',
            gradientToColors: ['#87D4F9'],
            stops: [0, 100]
          }
        },
        stroke: {
          lineCap: 'round'
        },
        labels: ['Repeat']
      },
      series: [70]
    }
  },
  methods:{
    ...mapActions({
            fetchQuestionPractice:'auth/fetchGraphData'
        }),
        ...mapGetters({
            QuestionResponse: 'auth/UPDATEQuestionStatus',
            GetQuestionPractice:'auth/getPracticeNumber'
        }),
        getPracticeData(){
          const currentUser=this.$ls.get('user')
          const url='overallPractice/getPracticeCount/id='+currentUser.user_id
          this.fetchQuestionPractice(url).then(()=>{
                const response=this.GetQuestionPractice()
                //console.log(response)
                const practiceData=response.data
                practiceData.totalDays =(practiceData.totalDays==0)?1:practiceData.totalDays
                const perday =Math.round(practiceData.total/practiceData.totalDays)
                const newData=[practiceData.today, practiceData.total,perday]
                //console.log(newData)
                //console.log(this.barseries[0].data)
                this.barseries = [{
                  data: newData
                }]
            })
        },
        getPredictExam(){
          const url='overallPractice/getPredictExamCount'
          this.fetchQuestionPractice(url).then(()=>{
                const response=this.GetQuestionPractice()
                //console.log(response)
                const predictData=response.data
                this.predictExam.predict=predictData.predict
                this.predictExam.exam=predictData.exam
                this.predictExam.New=predictData.newQuest
            })
        },
        questionStatusView(type){
          if(type=='predict')
          {
            if(this.user_type!='premium'){
              return this.$alert('You need to upgrade your package to access predicted questions.')
            }
            else{
                this.$parent.$parent.changeView(type)
            }
          }
          else{
            this.$parent.$parent.changeView(type)
          }
          
        },
        showStudyCenter(menuItem){
          this.$parent.$parent.callMenuClick(menuItem)
        },
        viewPredictExamQuest(){
          const url='overallPractice/getPredictExamQuestList'
          this.fetchQuestionPractice(url).then(()=>{
                const response=this.GetQuestionPractice()
                //console.log(response)
                //const predictData=response.data
            })
        }
  },
  beforeMount(){
    this.getPracticeData()
    this.getPredictExam()
    this.user_type=this.$ls.get('user').user_type
  }
}
</script>
<style>
.colorChange
{
  color: #001848;
}
.text-redSuccess
{
    color: #1e467f !important;
}
.viewAll{
  bottom: 12px;
position: absolute;
padding-right: 10px;

}
.studyCenter,.viewAll{
border-top: 1px solid #ccc;
}
</style>