<template>
<div>
    <div class="row">
        <div class="col-xs-12 col-sm-12 col-md-12 col-xl-6 border-right border-bottom pb-3  pt-3">
          <h5 class="card-title">All Users Study Stats</h5>
          <apexchart type="bar" :options="baroptions" :series="barseries"></apexchart>
        </div>
        <div class="col-xs-12 col-sm-12 col-md-12 col-xl-6 border-bottom pb-3 pt-3">
          <h5 class="card-title">Users Count</h5>
          <div class="row colorChange">
            <div class="col-md-7 m-auto">
              <apexchart type="radialBar" :options="options" :series="series"></apexchart>
            </div>
            <div class="col-md-5">
              <div class="card mb-3 widget-content pointer-courser" >
                  <div class="widget-content-outer">
                    <div class="widget-content-wrapper">
                        <div class="widget-content-left">
                          <div class="widget-heading">Free User</div>
                          </div>
                          <div class="widget-content-right">
                              <div class="widget-numbers text-redSuccess">{{userCount.freeUser}}</div>
                          </div>
                      </div>
                  </div>
              </div>
              <div class="card mb-3 widget-content pointer-courser">
                  <div class="widget-content-outer">
                    <div class="widget-content-wrapper">
                        <div class="widget-content-left">
                          <div class="widget-heading">Premium User</div>
                          </div>
                          <div class="widget-content-right">
                              <div class="widget-numbers text-redSuccess">{{userCount.preUser}}</div>
                          </div>
                      </div>
                  </div>
              </div>
              <div class="card mb-3 widget-content pointer-courser">
                  <div class="widget-content-outer">
                    <div class="widget-content-wrapper">
                        <div class="widget-content-left">
                          <div class="widget-heading">Standard User</div>
                          </div>
                          <div class="widget-content-right">
                              <div class="widget-numbers text-redSuccess">{{userCount.standUser}}</div>
                          </div>
                      </div>
                  </div>
              </div>
            </div>
          </div>
        </div>
    </div>
    <div class="row">
      <div class="col-xs-12 col-sm-12 col-md-12 col-xl-6 border-right border-bottom pb-3 pt-4">
          <h5 class="card-title">Coaching Users</h5>
          <apexchart type="bar" :options="coachingbaroptions" :series="coachingbarseries"></apexchart>
        </div>
        <div class="col-xs-12 col-sm-12 col-md-12 col-xl-6 border-bottom pb-5 pt-4 pl-5 pr-5">
          <h5 class="card-title">Coaching Registered Analysis</h5>
          <apexchart type="donut" :options="coachingNoCoachingbaroptions" :series="coachingNoCoachingbarseries"></apexchart>
        </div>
    </div>
    <!-- <div class="row">
      <div class="col-xs-12 col-sm-12 col-md-6 col-md-12 col-xl-6 border-right pt-3">
        <div class="d-flex">
          <div class="ml-auto p-2 mb-2 mr-2 btn-transition btn btn-outline-info pointer-courser" @click="showStudyCenter('study-center')">Study center</div>
        </div>
      </div>
      <div class="col-xs-12 col-sm-12 col-md-12 col-xl-6 pt-3">
        <div class="d-flex">
          <div class="ml-auto p-2 mb-2 mr-2 btn-transition btn btn-outline-success  pointer-courser" @click="questionStatusView('all')">View all</div>
        </div>
      </div>
    </div> -->
  </div>
</template>
<script>
import apexchart from 'vue-apexcharts'
import { mapActions,mapGetters } from 'vuex'
export default {
  components: {
    apexchart
  },
  data () {
    return {
      user_type:'',
      userCount:{
        preUser:0,
        standUser:0,
        freeUser:10
      },
      baroptions: {
        chart: {
          id: 'Practice'
        },
        colors: ['#001848','#194db5','#5979b7'],
        plotOptions: {
          bar: {
            distributed: true
          }
        },
        xaxis: {
          categories: ['Today Practiced', 'Weekly Practiced', 'Prac. Days']
        }
      },
      barseries: [{
        name: 'Practice Data',
        data: [0, 0, 0]
      }],
      coachingbaroptions: {
        chart: {
          id: 'Coaching'
        },
        colors: ['#1d4d94','#606a84','#2f5a85','#3c67c0'],
        plotOptions: {
          bar: {
            distributed: true
          }
        },
        xaxis: {
          categories: ['1-Month', '3-Month', '6-Month','12-Month']
        }
      },
      coachingbarseries: [{
        name: 'Coaching User Data',
        data: [0, 0, 0, 0]
      }],
      coachingNoCoachingbaroptions: {
        chart: {
          id: 'Coaching-NoCoaching'
        },
        colors: ['#1d4888','#f23276'],
        labels: ['No Coaching', 'Coaching'],
          plotOptions: {
        pie: {donut: {
              size: '60%',
              labels: {
              show: true,
        },
        }}
          }
      },
      coachingNoCoachingbarseries: [0, 0],
      options: {
        chart: {
          height: 280,
          type: 'radialBar'
        },
        series: [67],
        colors: ['#001848'],
        plotOptions: {
          radialBar: {
            hollow: {
              margin: 0,
              size: '70%',
              background: '#293450'
            },
            track: {
              dropShadow: {
                enabled: true,
                top: 2,
                left: 0,
                blur: 4,
                opacity: 0.15
              }
            },
            dataLabels: {
              name: {
                offsetY: -10,
                color: '#fff',
                fontSize: '13px'
              },
              value: {
                color: '#fff',
                fontSize: '30px',
                show: true
              }
            }
          }
        },
        fill: {
          type: 'gradient',
          gradient: {
            shade: 'dark',
            type: 'vertical',
            gradientToColors: ['#87D4F9'],
            stops: [0, 100]
          }
        },
        stroke: {
          lineCap: 'round'
        },
        labels: ['Paid']
      },
      series: [70]
    }
  },
  methods:{
    ...mapActions({
            fetchQuestionPractice:'auth/fetchGraphData'
        }),
        ...mapGetters({
            QuestionResponse: 'auth/UPDATEQuestionStatus',
            GetQuestionPractice:'auth/getPracticeNumber'
        }),
        getPracticeData(){
          //const currentUser=this.$ls.get('user')
          const url='overallPractice/GetAllUsersPracticeCount'
          this.fetchQuestionPractice(url).then(()=>{
                const response=this.GetQuestionPractice()
                //console.log(response)
                const practiceData=response.data
                practiceData.totalDays =(practiceData.totalDays==0)?1:practiceData.totalDays
                const perday =Math.round(practiceData.total/practiceData.totalDays)
                const newData=[practiceData.today, practiceData.weekly,perday]
                //console.log(newData)
                //console.log(this.barseries[0].data)
                this.barseries = [{
                  data: newData
                }]
            })
        },
        getCoachingData(){
          //const currentUser=this.$ls.get('user')
          const url='overallPractice/GetCoachingUserTypeCount'
          this.fetchQuestionPractice(url).then(()=>{
                const response=this.GetQuestionPractice()
                //console.log(response)
                const coachingData=response.data
                const newData=[coachingData.Month1, coachingData.Month3,coachingData.Month6,coachingData.Month12]
                //console.log(newData)
                //console.log(this.barseries[0].data)
                this.coachingbarseries = [{
                  data: newData
                }]
            })
        },
        getCoachingNoCoachingData(){
          //const currentUser=this.$ls.get('user')
          const url='overallPractice/GetCoachingNoCoachingCount'
          this.fetchQuestionPractice(url).then(()=>{
                const response=this.GetQuestionPractice()
                //console.log(response)
                const coachingNoCoachingData=response.data
                const newData=[coachingNoCoachingData.NoCoaching, coachingNoCoachingData.Coaching]
                //console.log(newData)
                //console.log(this.coachingNoCoachingbarseries)
                this.coachingNoCoachingbarseries = newData
            })
        },
        getUserCount(){
          const url='overallPractice/GetUserTypeCount'
          this.fetchQuestionPractice(url).then(()=>{
                const response=this.GetQuestionPractice()
                //console.log(response)
                const countData=response.data
                this.userCount.preUser=countData.preUser
                this.userCount.standUser=countData.standUser
                this.userCount.freeUser=countData.freeUser
                var calculatePerSeries=Math.round((countData.paidUser/countData.totalUser)*100);
                this.series=[calculatePerSeries]
                this.options.fill.gradient.stops=[0,countData.totalUser]
            })
        },
        questionStatusView(type){
          if(type=='predict')
          {
            if(this.user_type!='premium'){
              return this.$alert('You need to upgrade your package to access predicted questions.')
            }
            else{
                this.$parent.$parent.changeView(type)
            }
          }
          else{
            this.$parent.$parent.changeView(type)
          }
          
        },
        showStudyCenter(menuItem){
          this.$parent.$parent.callMenuClick(menuItem)
        },
        viewPredictExamQuest(){
          const url='overallPractice/getPredictExamQuestList'
          this.fetchQuestionPractice(url).then(()=>{
                const response=this.GetQuestionPractice()
                //console.log(response)
                //const predictData=response.data
            })
        }
  },
  beforeMount(){
    this.getPracticeData()
    this.getUserCount()
    this.getCoachingData()
    this.getCoachingNoCoachingData()
    this.user_type=this.$ls.get('user').user_type
  }
}
</script>
<style>
.colorChange
{
  color: #001848;
}
.text-redSuccess
{
    color: #1e467f !important;
}
</style>