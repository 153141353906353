<template>
<div class="app-main__inner dashBoardContent">
    <div class="row">
        <div class="col-md-12 col-sm-12 fullWidth">
            <div class="app-page-title dashboard-title">
                <div class="page-title-wrapper">
                    <div class="page-title-heading">
                        <div class="page-title-icon">
                            <i class="pe-7s-server icon-gradient bg-happy-itmeo">
                            </i>
                        </div>
                        <div>Records & Prediction
                        </div>
                    </div>
                </div>
            </div>
        </div>
    </div>
    <!--  Admin Graphs Start -->
    <AdminGraphs v-if="isAdmin=='T'"/>
    <Graphs v-else/>
    <!--  Admin Graphs End -->
    <div class="row  mt-2 ml-2 mr-2">
        <div class="col-md-12 col-sm-12 fullWidth bottomMargin">
            <div class="app-page-title dashboard-title mt-1">
                <div class="page-title-wrapper">
                    <div class="page-title-heading">
                        <div class="page-title-icon">
                            <i class="pe-7s-study icon-gradient bg-happy-itmeo">
                            </i>
                        </div>
                        <div>Learning Tools</div>
                    </div>
                </div>
            </div>
        </div>
    </div>
    <div class="row">
        <div v-for="(studyTool, index) in studyTools" class="col-sm-12 col-md-12 col-lg-4" :key="index">
            <div class="card mb-3 widget-content pointer-courser" :class="[ studyTool.bg ]" @click="studyToolClick(studyTool.clickItem)">
                <div class="widget-content-wrapper text-white">
                    <div class="widget-content-left">
                        <div class="widget-heading">{{ studyTool.title }}</div>
                        <div class="widget-subheading">{{ studyTool.subtitle }}</div>
                    </div>
                    <div class="widget-content-right">
                        <div class="text-white"><span>{{ studyTool.value }}</span></div>
                    </div>
                </div>
            </div>
        </div>
    </div>
    <!-- <div class="row">
        <div class="col-md-12 col-sm-12 fullWidth bottomMargin">
            <div class="app-page-title dashboard-title mt-1">
                <div class="page-title-wrapper">
                    <div class="page-title-heading">
                        <div class="page-title-icon">
                            <i class="pe-7s-display2 icon-gradient bg-happy-itmeo">
                            </i>
                        </div>
                        <div>Study Guide</div>
                    </div>
                </div>
            </div>
        </div>
    </div>
    <div class="row">
        <div v-for="(studyGuide, index) in studyGuides" class="col-sm-12 col-md-12 col-lg-3 col-xl-2" :key="index">
            <div class="card mb-3 widget-content" :class="[ studyGuide.bg ]">
                <div class="widget-content-wrapper text-white">
                    <div class="text-center w-100">
                        <i class="fas fa-3x" :class="[ studyGuide.icon ]"> </i>
                        <div class="widget-heading">{{ studyGuide.title }}</div>
                    </div>
                </div>
            </div>
        </div>
    </div> -->
</div>
</template>
<script>
import Graphs from '@/components/admin/Graphs'
import AdminGraphs from '@/components/admin/AdminGraphs'
export default {
  props:["isAdmin"],
  data () {
    return {
      studyTools: [
        {
          title: 'Vocabulary',
          subtitle: 'exam vocab',
          bg: 'bg-midnight-bloom',
          value: 'Contain 90%',
          clickItem:'vocab-books'
        },
        // {
        //   title: 'Shadowing',
        //   subtitle: 'Improve oral in',
        //   bg: 'bg-arielle-smile',
        //   value: '14 days'
        // },
        {
          title: 'AI Analysis',
          subtitle: 'Report analysis',
          bg: 'bg-grow-early',
          value: 'Accurate Score',
          clickItem:'study-center'
        },
        {
          title: 'Mock Tests',
          subtitle: 'Exam tests',
          bg: 'bg-warning',
          value: 'Scored PTE',
          clickItem:'free-mock-tests' 
        }
      ],
      studyGuides: [
        {
          title: 'PTE Guide',
          bg: 'bg-midnight-bloom',
          icon: 'fa-book'
        },
        {
          title: 'Speaking',
          bg: 'bg-arielle-smile',
          icon: 'fa-microphone-alt'
        },
        {
          title: 'Writing',
          bg: 'bg-grow-early',
          icon: 'fa-pencil-alt'
        },
        {
          title: 'Reading',
          bg: 'bg-warning',
          icon: 'fa-search'
        },
        {
          title: 'Listening',
          bg: 'bg-night-fade',
          icon: 'fa-headphones'
        },
        {
          title: 'Templates',
          bg: 'bg-happy-green',
          icon: 'fa-file'
        }
      ]
    }
  },
  components: {
    Graphs,
    AdminGraphs
  },
  methods:{
    studyToolClick(clickItem){
      if(window.matchMedia("(max-width: 700px)").matches & clickItem==="mock-tests"){
        return this.$alert("Mock-tests feature is only available on Ipad/System/Laptops.","Information","warning");
      }
      document.body.scrollTop = 0; // For Safari
      document.documentElement.scrollTop = 0; // For Chrome, Firefox, IE and Opera
        this.$parent.callMenuClick(clickItem)
    }
  },
  beforeMount () {
    const recaptchaScript = document.createElement('script')
    recaptchaScript.setAttribute('src', 'admin/assets//scripts/main.js')
    document.head.appendChild(recaptchaScript)
  }
}
</script>
<style scoped>
.text-center.w-100 .widget-heading{
  font-size: 18px;
    font-weight: 500;
}
.text-center.w-100 .fas.fa-3x
{
  line-height: 3.2rem;
      font-size: 2.8em;
}
</style>